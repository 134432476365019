body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #101419;
  color: #dadae0;
  text-align: center;
}

button {
  cursor: pointer;
}

li {
  text-align: left;
  margin: 0.5rem 0;
}
